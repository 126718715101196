<script lang="ts">
	import { page } from '$app/stores';

	import ClerkLoaded from './ClerkLoaded.svelte';
</script>

<ClerkLoaded let:clerk>
	<!-- 
	NOTE:
	When in extension, $clerk does not work properly, but out session is nevertheless defined.
	For this reason, we rely on $page.data.profile to determine if the user is signed in, instead.
-->
	{#if import.meta.env.VITE_IS_EXTENSION === 'true' ? $page.data.profile === null : clerk && !clerk.user}
		<slot />
	{/if}
	<!-- {#if clerk && !clerk.user}
		<slot />
	{/if} -->
</ClerkLoaded>
